// T-92 rake task that generates react component updates like stimulus:manifest:update
import inputSettings from './settings/input'
import helpPrompts from './help-prompts'
import schedule from './schedule/schedule'
import scheduleView from './schedule/view'
import paperwork from './paperwork'
import notifications from './notifications'
import calendar from './calendar'
import messageFormSubject from './message-form/subject'
import messageFormInit from './message-form/init'
import messageFormEmailAttachment from './message-form/email-attachment'
import messageFormBody from './message-form/body'
import messageFormDiscard from './message-form/discard'
import messageFormTemplatePanel from './message-form/template-panel'
import messageFormComposeEmailTo from './message-form/compose-email-to'
import messageFormReset from './message-form/reset'
import messageFormTemplateButton from './message-form/template-button'
import bouncedEmailIndex from './bounced-email/index'
import setting from './setting'
import conversationsMessageBody from './conversations/messageBody'
import inactiveUserEmailAnnouncement from './inactive-user-email-announcement'
import fileUploaderLogo from './file-uploader/logo'
import fileUploaderJob from './file-uploader/job'
import fileUploaderIndex from './file-uploader/index'
import fileUploaderContact from './file-uploader/contact'
import fileUploaderEmailAttachments from './file-uploader/email-attachments'
import accreditations from './accreditations'
import modalExample from './modal/example'
import notificationsPanelBell from './notifications-panel/bell'
import notificationsPanelIndex from './notifications-panel/index'

// Object with paths mapping to the imported values
const modules = {
  'settings/input': inputSettings,
  'help-prompts': helpPrompts,
  'schedule/schedule': schedule,
  'schedule/view': scheduleView,
  paperwork,
  notifications,
  calendar,
  'message-form/subject': messageFormSubject,
  'message-form/init': messageFormInit,
  'message-form/email-attachment': messageFormEmailAttachment,
  'message-form/body': messageFormBody,
  'message-form/discard': messageFormDiscard,
  'message-form/template-panel': messageFormTemplatePanel,
  'message-form/compose-email-to': messageFormComposeEmailTo,
  'message-form/reset': messageFormReset,
  'message-form/template-button': messageFormTemplateButton,
  'bounced-email': bouncedEmailIndex,
  setting,
  'conversations/messageBody': conversationsMessageBody,
  'inactive-user-email-announcement': inactiveUserEmailAnnouncement,
  'file-uploader/logo': fileUploaderLogo,
  'file-uploader/job': fileUploaderJob,
  'file-uploader': fileUploaderIndex,
  'file-uploader/contact': fileUploaderContact,
  'file-uploader/email-attachments': fileUploaderEmailAttachments,
  accreditations,
  'modal/example': modalExample,
  'notifications-panel/bell': notificationsPanelBell,
  'notifications-panel': notificationsPanelIndex
}

export default modules
