import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['initialsInput', 'colorItem']
  static values = {
    initials: String,
    initialsSetManually: { type: Boolean, default: false }
  }

  handleNameChange (event) {
    if (this.initialsSetManuallyValue) return

    this.initialsValue = this.generateInitials(event.target.value)
    this.initialsInputTarget.value = this.initialsValue
    this.updateColorItems()
  }

  handleInitialsChange () {
    this.initialsSetManuallyValue = true

    this.initialsValue = this.initialsInputTarget.value
    this.updateColorItems()
  }

  generateInitials (name) {
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .substring(0, 3)
      .toUpperCase()
  }

  updateColorItems () {
    this.colorItemTargets.forEach(color => (color.innerText = this.initialsValue))
  }
}
