import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ItemContext } from '../../contexts'
import { getItem } from '../../selectors'
import { itemToggleOptional } from '../../actions'

const OptionalButton = () => {
  const dispatch = useDispatch()
  const { itemUUID } = useContext(ItemContext)
  const { optional } = useSelector(state => getItem(state, itemUUID))

  return (
    <button
      onClick={() => { dispatch(itemToggleOptional(itemUUID)) }}
      className={optional ? 'btn btn--sml u-mrgn-r--x2 active' : 'btn btn--sml u-mrgn-r--x2'}
      type='button'
      data-test='optional-item-button'
      data-controller='tooltip'
      data-tooltip-content-value='Mark item as optional'
    >
      Optional
    </button>
  )
}

export default OptionalButton
