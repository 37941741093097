import React from 'react'
import PropTypes from 'prop-types'

const UserInitials = ({ name, initials, color }) => (
  <span className={`company-user__initials company-user__initials--${color}`} data-controller='tooltip' data-tooltip-content-value={name}>
    <span>
      {initials}
    </span>
  </span>
)

UserInitials.propTypes = {
  name: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

export default UserInitials
