import { Controller } from 'stimulus'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

class TooltipController extends Controller {
  static values = {
    content: String,
    delay: { type: Array, default: [50, 0] }, // [show, hide]
    placement: { type: String, default: 'bottom' }
  }

  connect () {
    this.tippy = tippy(this.element, {
      content: this.contentValue,
      delay: [this.showDelayValue, this.hideDelayValue],
      placement: this.placementValue,
      theme: 'ytb',
      maxWidth: 400
    })
  }

  disconnect () {
    this.tippy.destroy()
  }
}

export default TooltipController
