import React, { useRef, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { SortableHandle } from 'react-sortable-hoc'

import TrashSvg from '@/components/svg/trash'
import MoveUpDownSvg from '@/components/svg/move-up-down'
import {
  materialItemFormChanged,
  materialItemRemove,
  materialAutocompleteChoose,
  materialItemDuplicate
} from '../../actions'
import { getMaterialItem, getConfiguration, isMaterialItemEmpty, getItem } from '../../selectors'
import materialItemValues from '../../schemas/material-item-values'
import { ItemContext, MaterialItemContext } from '../../contexts'
import significantDigits from '../../helpers/significant-digits'
import Field from '../final-form-field'
import Form from '../form'
import DescriptionField from './description-field'
import MoneyField from '../money-field'
import SaveButton from './save-button'
import ItemMaterialItemTotal from '../totals/items/material-item'
import MaterialItemTotal from '../totals/material-items'
import DuplicateSvg from '@/components/svg/duplicate'

const DragHandle = SortableHandle(() => <a className='m-table__row-drag-handle'><MoveUpDownSvg className='icon icon--tiny' /></a>)

const Item = ({
  materialItemUUID,
  isFirstItem
}) => {
  const dispatch = useDispatch()
  const { itemUUID } = useContext(ItemContext)
  const item = useSelector(state => getItem(state, itemUUID))
  const materialItem = useSelector(state => getMaterialItem(state, materialItemUUID))
  const formValues = materialItemValues(materialItem)
  const { changed, focussed, fromSearch } = materialItem
  const { currencyUnit, defaultMaterialMarkupPercentage } = useSelector(getConfiguration)
  const isEmpty = useSelector(state => isMaterialItemEmpty(state, materialItemUUID))
  const isFocussed = focussed || (isFirstItem && isEmpty)
  const isMultipliedByItemQuantity = item.quantity > 1

  const quantityInput = useRef(null)
  const scrollRef = useRef(null)
  const descriptionFieldRef = useRef(null)

  useEffect(() => {
    if (isFocussed) { scrollRef.current.scrollIntoView() }
  }, [])

  useEffect(() => {
    if (isFocussed) {
      setTimeout(() => {
        descriptionFieldRef.current.focus()
      }, 1)
    }
  }, [])

  const handleRemove = () => {
    if (changed && !window.confirm('Are you sure you want to remove this material? It cannot be undone')) {
      return
    }

    dispatch(materialItemRemove(itemUUID, materialItemUUID))
  }

  const handleDuplicate = () => {
    dispatch(materialItemDuplicate(itemUUID, materialItemUUID))
  }

  return (
    <MaterialItemContext.Provider value={{ materialItemUUID }}>
      <Form
        initialValues={formValues}
        onChange={values => {
          dispatch(materialItemFormChanged(materialItemUUID, itemUUID, values))
        }}
      >
        <ul ref={scrollRef} className={isEmpty ? 'm-table__row m-table__row--empty' : 'm-table__row'} data-test='material-item'>
          <li data-label='Material' className='m-table__cell m-table__cell--title'>
            <DragHandle />
            <DescriptionField
              onResultClick={result => {
                dispatch(materialAutocompleteChoose(materialItemUUID, result))
                quantityInput.current.focus()
              }}
              autoFocus={isFocussed}
              domReference={descriptionFieldRef}
            />
          </li>
          <li data-label={isMultipliedByItemQuantity ? 'Qty/item' : 'Qty'} className='m-table__cell m-table__cell--qty'>
            <Field
              name='quantity'
              className='m-table__input m-table__input--qty'
              testKey='quantity'
              placeholder='0'
              inputMode='decimal'
              maxLength='7'
              domReference={quantityInput}
              format={significantDigits}
              formatOnBlur
              selectOnFocus
              autoFocus={fromSearch}
            />
          </li>
          <li data-label='Unit type' className='m-table__cell m-table__cell--units'>
            <Field
              name='unitType'
              className='m-table__input'
              testKey='unit-type'
              selectOnFocus
            />
          </li>
          <li data-label={`${currencyUnit} per unit`} className='m-table__cell m-table__cell--price'>
            <MoneyField
              name='itemPrice'
              className='m-table__input m-table__input--price'
              testKey='item-price'
              placeholder='0.00'
              selectOnFocus
            />
          </li>
          <li data-label='% markup' className='m-table__cell m-table__cell--markup'>
            <span className='input--percentage'>
              <Field
                name='markupPercentage'
                className='m-table__input m-table__input--markup'
                testKey='markup-percentage'
                placeholder='0'
                selectOnFocus
                format={significantDigits}
                formatOnBlur
                defaultValue={defaultMaterialMarkupPercentage}
              />
            </span>
          </li>
          <li data-label='Total' className='m-table__cell m-table__cell--total'>
            <span className='m-table__input--total' data-test='material-item-total'>
              <MaterialItemTotal name='total' />
            </span>
          </li>
          {isMultipliedByItemQuantity && (
            <li data-label={`Total ${String.fromCharCode(215)} ${item.quantity}`} className='m-table__cell m-table__cell--total m-table__cell--multiple'>
              <span className='m-table__input--total' data-test='item-material-item-total'>
                <ItemMaterialItemTotal name='total' />
              </span>
            </li>
          )}
          <li className='m-table__cell m-table__cell--actions'>
            <SaveButton />
            <button
              data-test='duplicate-material-item-button'
              data-controller='tooltip'
              data-tooltip-content-value='Duplicate this item'
              title='Duplicate this item'
              className='btn btn--tiny btn--icon-only'
              type='button'
              onClick={handleDuplicate}
            >
              <DuplicateSvg className='icon icon--tiny' />
            </button>
            <button
              data-test='remove-material-item-button'
              className='btn btn--tiny btn--icon-only'
              type='button'
              onClick={handleRemove}
            >
              <TrashSvg className='icon icon--tiny' />
            </button>
          </li>
        </ul>
      </Form>
    </MaterialItemContext.Provider>
  )
}

Item.propTypes = {
  materialItemUUID: PropTypes.string.isRequired,
  isFirstItem: PropTypes.bool.isRequired
}

export default Item
