/* eslint-disable */
if (document.querySelector('[data-js="refer-url"]')) {
  const referURL = document.querySelector('[data-js="refer-url"]').value;
  const facebookShare = document.querySelector('[data-js="facebook-share"]');
  const twitterShare = document.querySelector('[data-js="twitter-share"]');
  const emailShare = document.querySelector('[data-js="email-share"]');

  window.fbAsyncInit = function () {
    FB.init({
      appId: window.App.facebookAppKey,
      xfbml: true,
      version: 'v2.8',
    });
  };

  (function (d, s, id) {
    let js; const
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  facebookShare.addEventListener('click', e => {
    e.preventDefault();
    FB.ui({
      method: 'share',
      quote: `YourTradebase is helping me smash through admin and save tonnes of time, every week. If you fancy giving it a go, help yourself to a nifty ${referCredit} credit.`,
      href: referURL,
    }, response => {});
  });

  twitterShare.addEventListener('click', e => {
    e.preventDefault();
    const referText = `I’m saving a tonne of time on admin with YourTradebase. If you fancy giving it a go, help yourself to a nifty ${referCredit} credit: `;
    const referHashtags = 'YourTradebase, FreedomFromPaperwork, SaveTime, Nifty50';
    const popupWidth = 600;
    const popupHeight = 350;
    const popupLeft = window.screenX + (window.innerWidth / 2) - (popupWidth / 2);
    const popupTop = window.screenY + (window.innerHeight / 2) - (popupHeight / 2);
    const twitterWindow = window.open(`https://twitter.com/share?text=${referText}&url=${referURL}&hashtags=${referHashtags}`, 'twitter-popup', `height=${popupHeight},width=${popupWidth},left=${popupLeft},top=${popupTop}`);
    if (twitterWindow.focus) { twitterWindow.focus(); }
    return false;
  });
}
