import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'

import DuplicateSvg from '@/components/svg/duplicate'
import { ItemContext } from '../../contexts'
import { itemDuplicate } from '../../actions'

const DuplicateButton = () => {
  const dispatch = useDispatch()
  const { itemUUID } = useContext(ItemContext)

  return (
    <button
      onClick={() => { dispatch(itemDuplicate(itemUUID)) }}
      className='btn btn--sml u-mrgn-r--x2'
      type='button'
      data-test='duplicate-item-button'
      data-controller='tooltip'
      data-tooltip-content-value='Duplicate this item'
      title='Duplicate this item'
    >
      <DuplicateSvg className='icon icon--tiny' />
    </button>
  )
}

export default DuplicateButton
