import React from 'react'
import PropTypes from 'prop-types'
import TrashSvg from '@/components/svg/trash'

const RemoveButton = ({ onClick }) => (
  <button
    data-test='remove-item-button'
    className='btn btn--sml btn--icon-only'
    type='button'
    onClick={onClick}
    data-controller='tooltip'
    data-tooltip-content-value='Delete this item'
    title='Delete this item'
  >
    <TrashSvg className='icon icon--tiny' />
  </button>
)

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default RemoveButton
