import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { itemQuantityIncrement } from '../../actions'
import { ItemContext } from '../../contexts'
import { getItem } from '../../selectors'

const QuantityIncrementButton = () => {
  const dispatch = useDispatch()
  const { itemUUID } = React.useContext(ItemContext)
  const { quantity } = useSelector(state => getItem(state, itemUUID))

  return (
    <button
      onClick={() => { dispatch(itemQuantityIncrement(itemUUID)) }}
      type='button'
      className='btn btn--sml'
      data-test='quantity-increment-button'
      data-controller='tooltip'
      data-tooltip-content-value='Increase quantity of this item'
      title='Increase quantity of this item'
      disabled={quantity >= 99999}
    >
      + Qty
    </button>
  )
}

export default QuantityIncrementButton
