import { Controller } from '@hotwired/stimulus'

export default class HighlightController extends Controller {
  static targets = ['content']

  selectText () {
    setTimeout(() => {
      this.contentTarget.select()
    }, 20)
  }
}
