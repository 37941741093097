import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SaveSvg from '@/components/svg/save'
import CheckCircleSvg from '@/components/svg/check-circle'

import { LabourItemContext } from '../../contexts'
import { labourItemSave } from '../../actions'
import { getLabourItem, getErrors } from '../../selectors'

const SaveButton = () => {
  const dispatch = useDispatch()
  const { labourItemUUID } = useContext(LabourItemContext)
  const {
    description,
    saved,
    fromAutocomplete
  } = useSelector(state => getLabourItem(state, labourItemUUID))
  const hasErrors = !!useSelector(state => getErrors(state, labourItemUUID))
  const canSave = !fromAutocomplete && description !== ''

  if (!canSave) { return null }

  const buttonColor = hasErrors && !saved ? 'btn--border-green' : ''

  return (
    <button
      onClick={() => dispatch(labourItemSave(labourItemUUID))}
      disabled={saved}
      className={`btn btn--tiny btn--icon-only ${buttonColor}`}
      type='button'
      data-test='save-labour-button'
      data-controller='tooltip'
      data-tooltip-content-value={saved ? '' : 'Save to your labour rates'}
    >
      {saved
        ? <CheckCircleSvg className='icon icon--tiny icon--green' />
        : <SaveSvg className='icon icon--tiny' />}
    </button>
  )
}

export default SaveButton
