import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['child', 'parent']

  toggle (event) {
    if (event.target.dataset.isparent === 'true') {
      this.childTargets.forEach(child => {
        child.checked = this.parentTarget.checked
      })
    } else {
      this.parentTarget.checked = true
    }
  }
}
