import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { itemQuantityDecrement } from '../../actions'
import { ItemContext } from '../../contexts'
import { getItem } from '../../selectors'

const QuantityDecrementButton = () => {
  const dispatch = useDispatch()
  const { itemUUID } = React.useContext(ItemContext)
  const { quantity } = useSelector(state => getItem(state, itemUUID))

  return (
    <button
      onClick={() => { dispatch(itemQuantityDecrement(itemUUID)) }}
      type='button'
      className='btn btn--sml'
      data-test='quantity-decrement-button'
      data-controller='tooltip'
      data-tooltip-content-value='Decrease quantity of this item'
      title='Decrease quantity of this item'
      disabled={quantity <= 1}
    >
      - Qty
    </button>
  )
}

export default QuantityDecrementButton
