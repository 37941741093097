import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['country', 'state']

  connect () {
    this.toggle()
  }

  toggle () {
    if (this.countryTarget.value === 'US') {
      this.stateTarget.classList.remove('hidden')
    } else {
      this.stateTarget.classList.add('hidden')
    }
  }
}
