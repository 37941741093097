import { Controller } from 'stimulus'

export default class extends Controller {
  static classes = ['active']
  static targets = ['trigger', 'tab']
  static values = { selectedTabId: String }

  select (event) {
    this.selectedTabIdValue = event.currentTarget.id
  }

  selectedTabIdValueChanged (selectedTabId) {
    const selectedTab = this.getSelectedTab(selectedTabId)
    const selectedTrigger = this.getSelectedTrigger(selectedTabId)

    this.resetTabs()
    selectedTab.hidden = false
    selectedTrigger.classList.add(...this.activeClasses)
  }

  resetTabs () {
    this.tabTargets.forEach(tab => (tab.hidden = true))
    this.triggerTargets.forEach(trigger => trigger.classList.remove(...this.activeClasses))
  }

  getSelectedTab (id) {
    return this.tabTargets.find(element => element.id === id)
  }

  getSelectedTrigger (id) {
    return this.triggerTargets.find(element => element.id === id)
  }
}
